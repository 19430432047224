<template>
  <div
    class="w-full mb-4 bg-white rounded-xl shadow-lg text-gray-600"
    :v-if="complaint"
  >
    <div
      class="px-6 py-2 rounded-lg flex justify-between items-center cursor-pointer"
      @click="toggleAccordion"
      style="border-bottom: gray solid 1px"
    >
      <p class="text-dark text-left"><b>Detalle de la queja</b></p>
      <span
        class="h-5 w-5 m-2 flex justify-center items-center transition"
        :class="{ 'transform rotate-180': accordionOpen }"
      >
        <img src="@/assets/icons/arrow-down.svg" alt="" />
      </span>
    </div>
    <div v-if="accordionOpen" class="p-3">
      <div v-if="complaint && customer" class="w-full">
        <ComplaintDetailHeaderCard
          :radicado="complaint.codigo_queja"
          detail="true"
          :status="complaint.estado_cod"
          :principal="complaint.duplicity ? complaint.duplicity : null"
        />
        <h2 class="my-5 text-xl text-left text-blue">
          <b>Datos de contacto</b>
        </h2>
        <div class="grid grid-cols-2 gap-4 w-full">
          <BaseRadio
            ref="personType"
            name="personType"
            id="personType"
            label="Tipo de persona"
            :radioOptions="formOptions.optionsPersonType"
            v-model="customer.person_type"
            disabled="true"
          />
        </div>
        <CustomerNatural
          v-if="customer && customer.person_type == 1"
          :customer="customer"
        />
        <CustomerLegal
          v-if="customer && customer.person_type == 2"
          :customer="customer"
        />
      </div>
      <div class="w-11/12 grid grid-cols-2 gap-2">
        <BaseRadio
          ref="inconformidad"
          name="inconformidad"
          label="¿Inconformidad que motiva su queja ocurrió en el exterior?"
          :radioOptions="optionsNonconformity"
          v-model="complaint.foreign"
          :disabled="true"
        />
      </div>
      <div class="flex flex-row gap-2">
        <div
          class="w-11/12 grid grid-cols-2 gap-2"
          v-if="complaint.abroad == 1"
        >
          <BaseInput
            type="text"
            label="País"
            v-model="complaint.codigo_pais"
            placeholder=""
            :disabled="true"
          />
        </div>
        <div
          class="w-11/12 grid grid-cols-2 gap-2"
          v-if="complaint.abroad == 2"
        >
          <BaseInput
            type="text"
            label="Departamento"
            v-model="complaint.departamento_cod"
            placeholder=""
            :disabled="true"
          />
          <BaseInput
            type="text"
            label="Municipio"
            v-model="complaint.municipio_cod"
            placeholder=""
            :disabled="true"
          />
        </div>
      </div>
      <div class="w-11/12 grid grid-cols-1 gap-2">
        <BaseInput
          type="text"
          label="Entidad con la que se tiene la inconformidad"
          v-model="complaint.entidad_cod"
          placeholder=""
          :disabled="true"
        />
      </div>
      <div class="w-11/12 grid grid-cols-1 gap-2">
        <BaseInput
          type="text"
          label="La inconformidad está relacionada con alguno de los siguientes productos"
          v-model="complaint.producto_cod"
          placeholder=""
          :disabled="true"
        />
      </div>
      <div class="w-11/12 grid grid-cols-1 gap-2">
        <BaseInput
          type="text"
          label="Motivo de su inconformidad"
          v-model="complaint.macro_motivo_cod"
          placeholder=""
          :disabled="true"
        />
      </div>

      <div class="w-11/12 grid grid-cols-1 gap-2" v-if="this.showInpus">
        <!-- Cambiar los valores de los atributos selectOptions y v-model  -->
        <BaseSelect
          v-if="complaint.tipo_fraude && complaint.tipo_fraude != null"
          ref="tipo_fraude"
          id="tipo_fraude"
          label="Tipo de fraude"
          required="true"
          :disabled="true"
          v-model="complaint.tipo_fraude"
        />

        <BaseSelect
          v-if="
            complaint.modalidad_fraude && complaint.modalidad_fraude != null
          "
          ref="modalidad_fraude"
          id="modalidad_fraude"
          label="Modalidad fraude"
          required="true"
          :disabled="true"
          v-model="complaint.modalidad_fraude"
        />

        <BaseInput
          v-if="complaint.monto_reclamado && complaint.monto_reclamado != null"
          ref="monto_reclamado"
          type="text"
          label="Monto reclamado"
          :disabled="true"
          v-model="complaint.monto_reclamado"
        />
        <BaseInput
          v-if="
            complaint.monto_reconocido && complaint.monto_reconocido != null
          "
          ref="monto_reconocido"
          type="text"
          label="Monto reconocido"
          :disabled="true"
          v-model="complaint.monto_reconocido"
        />
        <!-- Cierre nuevos campos -->
      </div>

      <div class="flex flex-row gap-2">
        <div class="w-11/12 grid grid-cols-1 gap-2">
          <BaseTextarea
            ref="producto"
            id="producto"
            label="Detalle del producto"
            placeholder="Mensaje"
            v-model="complaint.producto_nombre"
            :disabled="true"
            maxlength="100"
          />
        </div>
      </div>
      <div class="w-11/12 grid grid-cols-1 gap-2">
        <BaseInput
          type="text"
          label="Canal"
          v-model="complaint.canal_cod"
          placeholder=""
          :disabled="true"
        />
      </div>
      <div class="flex flex-row gap-2">
        <div class="w-11/12 grid grid-cols-1 gap-2">
          <BaseTextarea
            ref="hechos"
            id="hechos"
            label="Hechos"
            placeholder="Mensaje"
            v-model="complaint.texto_queja"
            :disabled="true"
            maxlength="4500"
          />
        </div>
      </div>
      <div class="w-11/12 grid grid-cols-2 gap-2">
        <BaseRadio
          ref="attorney"
          name="attorney"
          label="Actúo en nombre propio o como apoderado"
          :radioOptions="optionsApoderado"
          v-model="complaint.es_apoderado"
          :disabled="true"
        />
      </div>
      <div
        class="w-11/12 grid grid-cols-1 gap-2"
        v-if="complaint.es_apoderado === 2"
      >
        <BaseInput
          type="text"
          label="Nombre completo del apoderado"
          v-model="complaint.apoderado_nombre"
          placeholder=""
          :disabled="true"
        />
        <BaseInput
          ref="attorneyEmail"
          type="text"
          id="apoderadoEmail"
          label="Correo electrónico del apoderado:"
          placeholder="Ej: apoderado@ejemplo.com"
          v-model="complaint.apoderado_correo"
          :disabled="true"
        />
      </div>

      <div
        v-if="complaint && complaint.archivos_apoderado.length > 0"
        class="flex flex-col gap-2"
      >
        <label
          class="text-xs md:text-xs text-gray-600 text-opacity-80 mt-5 text-left"
          ><b>Anexar autorización del poder</b></label
        >
        <div class="w-11/12 grid grid-cols-1 gap-2">
          <BaseDownload
            v-for="(option, index) in complaint.archivos_apoderado"
            :value="option.name_file"
            :key="index"
            :url_download="option.url_file"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CustomerNatural from "@/components/Dashboard/Customers/CustomerNatural";
import CustomerLegal from "@/components/Dashboard/Customers/CustomerLegal";
import ComplaintDetailHeaderCard from "@/components/Dashboard/Complaints/ComplaintDetailHeaderCard";

export default {
  props: ["complaint", "customer"],
  components: {
    ComplaintDetailHeaderCard,
    CustomerLegal,
    CustomerNatural,
  },
  data() {
    return {
      abroad: 0,
      showInpus: false,
      optionsNonconformity: [
        { id: true, name: "Sí" },
        { id: false, name: "No" },
      ],
      optionsAttorney: [
        { id: false, name: "A nombre propio" },
        { id: true, name: "Soy apoderado" },
      ],
      accordionOpen: false,
      optionsApoderado: [
        { id: 1, name: "Actúo en nombre propio" },
        { id: 2, name: "Soy apoderado" },
      ],
    };
  },
  async created() {
    let optionsReasonsCompany = await this.$store.dispatch(
      "form/loadReasonsOpt",
      { company: this.complaint.company }
    );

    const response = optionsReasonsCompany.some(({ name, is_fraud }) => {
      if (
        name.toUpperCase() == this.complaint.macro_motivo_cod.toUpperCase() &&
        is_fraud
      ) {
        return true;
      } else {
        return false;
      }
    });

    response ? (this.showInpus = true) : (this.showInpus = false);
  },
  computed: {
    ...mapState({
      formOptions: (state) => {
        return state.form;
      },
    }),
  },
  methods: {
    toggleAccordion() {
      this.accordionOpen = !this.accordionOpen;
    },
  },
};
</script>